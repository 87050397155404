import { Col, Form, Input, Radio, Row } from 'antd';
import parse from 'html-react-parser';
import loadScript from 'load-script';
import { BlockType, WidgetFormat } from 'pn-backend';
import { identity } from 'ramda';
import React, { FC, useEffect, useMemo } from 'react';

import { usePost } from '~hooks';
import { BlockProps } from '~types';
import { canBeFullscreen, getTypeWidget, isDefined, vkWidget, WidgetType } from '~utils';

import { FormBlock } from '../Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;
export const widgetScripts = {
  [WidgetType.facebook]: {
    crossorigin: 'anonymous',
    id: 'facebook-wjs',
    nonce: 'SRzOQrHN',
    src: 'https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v15.0',
  },
  [WidgetType.inst]: {
    id: 'inst-wjs',
    src: 'https://platform.instagram.com/en_US/embeds.js',
  },
  [WidgetType.twitter]: {
    id: 'twitter-wjs',
    src: 'https://platform.twitter.com/widgets.js',
  },
};

export const WidgetBlock: FC<BlockProps<BlockType.Widget>> = ({
  block,
  blockIndex,
  canDelete = false,
}) => {
  const [form] = Form.useForm();
  const { content } = block;
  const { html: defaultValue } = content || {};

  const { type: postType } = usePost();

  const preview = Form.useWatch('html', form);

  const initialValues = useMemo(
    () => ({
      ...content,
    }),
    [],
  );

  const html = preview || defaultValue;

  const type = getTypeWidget(html) as WidgetType;

  useEffect(() => {
    if (type === WidgetType.script) {
      const parsedHtml = parse(html) as { props: { id: string; src: string } };
      const src = parsedHtml?.props?.src || null;
      const id = parsedHtml?.props?.id || `widget-${blockIndex}`;
      if (isDefined(id) && isDefined(src)) {
        loadScript(src, { attrs: { id } }, identity);
      }
    } else if (type === WidgetType.vkPost) {
      vkWidget(html);
    } else {
      const script = widgetScripts[type];
      if (script) {
        loadScript(script.src, { attrs: { id: script.id } }, identity);
      }
    }
  }, [html, type]);

  return (
    <FormBlock
      block={block}
      blockIndex={blockIndex}
      form={form}
      initialValues={initialValues}
      canDelete={canDelete}
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <div className={styles.title}>Embed</div>
          <Form.Item name="html">
            <TextArea
              bordered={false}
              placeholder="Вставьте код"
              className={styles.input}
              autoSize
              rows={1}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="format">
            <Radio.Group>
              <Radio value={WidgetFormat.Center}>По центру</Radio>
              <Radio value={WidgetFormat.Wide}>По ширине</Radio>
              {canBeFullscreen(postType) && (
                <Radio value={WidgetFormat.Fullscreen}>Fullscreen</Radio>
              )}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <div className={styles.title}>Предпросмотр</div>
          <div className={styles.preview}>
            {html && <div className={styles.html} dangerouslySetInnerHTML={{ __html: html }} />}
          </div>
        </Col>
      </Row>
    </FormBlock>
  );
};
