import { Col, Row } from 'antd';
import React from 'react';

import { TitlePage } from '~components';
import { MobilePageHeader, SettingsSave, SettingsTabs } from '~containers';
import { SettingsProvider } from '~providers';

import { SettingsFilter } from '../../containers/SettingsFilter';

export const SettingsPage: React.FC = () => {
  return (
    <SettingsProvider>
      <Row gutter={[35, 35]}>
        <Col span={24}>
          <MobilePageHeader title="Настройки" placeholderText="Поиск" withSearch={false} />
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <TitlePage title="Настройки" />
            <Col>
              <Row gutter={[25, 25]}>
                <Col>
                  <SettingsSave />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <SettingsFilter />
        </Col>
        <Col span={24}>
          <SettingsTabs />
        </Col>
      </Row>
    </SettingsProvider>
  );
};
